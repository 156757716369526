import type { DivProps } from '@/types/common'
import clsx from 'clsx'
import styles from './ModalFooter.module.scss'

const ModalFooter = ({ className, children, ...props }: DivProps) => (
  <div className={clsx(styles.modal_footer, className)} {...props}>
    {children}
  </div>
)

export default ModalFooter

import Bugsnag, { type Event } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const bugsnagStart = () => {
  const isBugsnagStarted = Bugsnag.isStarted()
  const apiKey = process.env.NEXT_PUBLIC_BUGSNAG_API_AUTH
  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION
  if (!isBugsnagStarted && apiKey && appVersion) {
    const releaseStage = process.env.NEXT_PUBLIC_ENVIRONMENT || 'localhost'
    /* istanbul ignore next */
    const onError = (event: Event) => {
      event.addMetadata('Platform', { name: 'Next.js 13' })
      if (typeof window !== 'undefined' && window?.FS?.getCurrentSessionURL) {
        const urlAtTime = window.FS.getCurrentSessionURL(true)
        event.addMetadata('fullstory', { urlAtTime })
      }
    }

    Bugsnag.start({
      apiKey,
      appVersion,
      releaseStage,
      plugins: [new BugsnagPluginReact()],
      autoDetectErrors: true,
      onError,
    })
  }
}
const BugsnagService = { bugsnagStart }
export default BugsnagService

import type { APIResponse } from '@/types/api'
import type { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { APIError } from './error'

export type requestProps = { options?: RequestInit } & (
  | {
      url: string
      path?: undefined
    }
  | {
      path: string
      url?: undefined
    }
)

export const request = async ({ url, path, options }: requestProps) => {
  const urlToFetch = url ?? `${process.env.NEXT_PUBLIC_PULSE_API_URL}${path}`
  return fetch(urlToFetch, options)
}

export const requestJSON = async <T>(props: requestProps, defaultValue?: T) => {
  const response = await request(props)
  const failedResponse = !response.ok
  if (failedResponse && typeof defaultValue !== 'undefined') {
    return defaultValue
  }
  if (failedResponse) {
    const errorObj = { ...props, status: response.status }
    const errorMsg = `Error while calling ${JSON.stringify(errorObj)}`
    if (response.status >= 400 && response.status <= 500) {
      const json: APIResponse<unknown> = await response.json()
      throw new APIError(response.status, JSON.stringify(json))
    }
    throw new Error(errorMsg)
  }

  const json = await response.json()

  // Temporary fix to get different data structures from the API without modyfing existing
  // requestJSON calls. This will be fixed in a future PR.
  return json.data ?? json
}

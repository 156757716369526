import type { EventWithData, GAEvent } from '@/types/common'
import { useEffect, useState } from 'react'

const includeIfSet = <K extends keyof GAEvent, V>(key: K, value?: V) => {
  if (value === undefined) {
    return {}
  }
  return { [key]: value }
}

const useAnalyticsEventListener = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const dataListener = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const { dataset } = (target.closest('[data-evna]') || {}) as EventWithData
      if (!dataset) {
        return
      }

      const { evna: name, evco, evob, evpk, evpla } = dataset

      const gaEvent: GAEvent = {
        ...includeIfSet('component', evco),
        ...includeIfSet('object', evob),
        ...includeIfSet('pk', evpk),
        ...includeIfSet('platform', evpla),
      }

      window.gtag('event', name, gaEvent)
    }

    window.addEventListener('mousedown', dataListener)
    setIsLoaded(true)

    return () => {
      window.removeEventListener('mousedown', dataListener)
      setIsLoaded(false)
    }
  }, [])

  return { isLoaded }
}

export default useAnalyticsEventListener

import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import { PEER_FINDER_URL } from '@/components/organisms/NavHeader/constants'
import useProfile from '@/components/providers/profile/useProfile'
import DrawerSubMenu from '../../DrawerSubMenu'
import submenuStyles from '../../DrawerSubMenu.module.scss'
import { Sections } from '../../constants'
import styles from './Connect.module.scss'

const Connect = () => {
  const {
    profile: { hasPeerFinderAccess, groupsMembership },
  } = useProfile()

  return (
    <DrawerSubMenu label="Connect" parent={Sections.main}>
      <ul role="menu" className={submenuStyles.submenu_list}>
        {hasPeerFinderAccess && (
          <li>
            <Link href={PEER_FINDER_URL} role="menuitem" tabIndex={0}>
              Peer Finder
            </Link>
          </li>
        )}
        {process.env['NEXT_PUBLIC_PRIVATE_COMMUNITIES_ENABLED'] === 'true' && groupsMembership?.length > 0 && (
          <li className={styles.private_communities}>
            <Typography variant="disclaimer" size="small" className={styles.title} role="menuitem" tabIndex={0}>
              <b>My Private Communities</b>
            </Typography>
            <ul role="menu">
              {groupsMembership.map(({ name, slug }) => (
                <li key={slug}>
                  <Link href={`/ch/${slug}`} role="menuitem" tabIndex={0}>
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </DrawerSubMenu>
  )
}
export default Connect

'use client'

import { useRedirectToAuthUrl } from '@/components/hooks/useRedirectToAuthUrl'
import { UNAUTHORIZED } from '@/services/auth/constants'
import { getBrowserCookies } from '@/util/browser'
import { type ReactNode, useEffect, useMemo } from 'react'
import AppContext from './AppContext'

type AppProviderType = {
  children: ReactNode
  initialApp: {
    isBot: boolean
    joinGlassEnabled: boolean
  }
}

const AppProvider = ({ children, initialApp }: AppProviderType) => {
  const isBot = initialApp.isBot
  const joinGlassEnabled = initialApp.joinGlassEnabled

  const contextValue = useMemo(
    () => ({
      isBot,
      joinGlassEnabled,
    }),
    []
  )

  const { unauthorized } = useRedirectToAuthUrl()

  useEffect(() => {
    const redirect = getBrowserCookies()[UNAUTHORIZED]
    if (redirect === 'true') {
      unauthorized()
    }
  }, [])

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default AppProvider

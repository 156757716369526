'use client'
import { showToast } from '@/components/core/Toast'
import { AnonymousPublicProfile } from '@/constants/profile'
import { cleanLocalStorage, clientLogout } from '@/services/auth/auth'
import { HEADER_TOKEN } from '@/services/auth/constants'
import BugsnagService from '@/services/bugsnag/client'
import { navigate } from '@/services/cookies/cookiesServerActions'
import { isAPIError } from '@/services/error'
import type { PublicProfileType } from '@/types/api'
import { getBrowserCookies } from '@/util/browser'
import Bugsnag from '@bugsnag/js'
import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import LogoutFailed from './LogoutFailed'
import ProfileContext from './ProfileContext'

type ProfileProviderType = {
  children: ReactNode
  initialProfile: PublicProfileType
}

const ProfileProvider = ({ children, initialProfile }: ProfileProviderType) => {
  const [profile, setProfile] = useState<PublicProfileType>(initialProfile)

  const logout = useCallback(async () => {
    try {
      await clientLogout()
      navigate('/home')
      setProfile(AnonymousPublicProfile)
    } catch (e) {
      if (e instanceof Error) {
        if (!isAPIError(e)) {
          BugsnagService.bugsnagStart()
          Bugsnag.notify(`Error logging out: ${e.message}`, (event) => {
            event.unhandled = false
            event.severity = 'error'
          })
        }

        showToast({
          variant: 'info',
          content: <LogoutFailed />,
          dismissible: false,
        })
      }
    }
  }, [])

  const contextValue = useMemo(
    () => ({
      profile,
      logout,
      setProfile,
    }),

    [profile]
  )

  // Temporary fix to sync Nextweb cookies and SPA localStorage.
  // If we don't have the cookie, we are logged out, so we delete the token
  // from the localStorage to log out the SPA as well
  useEffect(() => {
    const cookies = getBrowserCookies()
    if (!cookies[HEADER_TOKEN]) {
      cleanLocalStorage()
    }
  }, [])

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>
}

export default ProfileProvider

import type React from 'react'
import { createContext, useCallback, useMemo, useState } from 'react'
import ModalRoot from './sub/ModalRoot'

export type ModalType = React.FC

interface ModalContextType {
  showModal(key: string, component: ModalType): void
  hideModal(key: string): void
}

const invariantViolation = () => {}

export const ModalContext = createContext<ModalContextType>({
  showModal: invariantViolation,
  hideModal: invariantViolation,
})
ModalContext.displayName = 'ModalContext'

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modals, setModals] = useState<Record<string, ModalType>>({})
  const showModal = useCallback(
    (key: string, modal: ModalType) =>
      setModals((modals) => ({
        ...modals,
        [key]: modal,
      })),
    []
  )
  const hideModal = useCallback(
    (key: string) =>
      setModals((modals) => {
        if (!modals[key]) {
          return modals
        }
        const newModals = { ...modals }
        delete newModals[key]
        return newModals
      }),
    []
  )
  const contextValue = useMemo(() => ({ showModal, hideModal }), [])

  if (typeof window !== 'undefined') {
    if (!Object.keys(modals).length) {
      document.body.style.overflow = 'initial'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <ModalContext.Provider value={contextValue}>
      <>
        {children}
        <ModalRoot modals={modals} />
      </>
    </ModalContext.Provider>
  )
}

import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import LinkedInIcon from '@/components/icons/LinkedInIcon'
import TwitterIcon from '@/components/icons/TwitterIcon'
import type React from 'react'
import Copyright from '../Copyright'
import styles from './Footer.module.scss'

type FooterProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

const Footer: React.FC<FooterProps> = (props) => (
  <section {...props}>
    <Typography variant="disclaimer" size="small" as="span" className={styles.links}>
      <p>
        <Link
          prefetch={false}
          href="https://www.gartner.com/reviews/guidelines?app=Community&hide_nav=true"
          target="_blank"
        >
          Community Guidelines
        </Link>
      </p>
      <p>
        <Link
          prefetch={false}
          href="https://www.gartner.com/reviews/faq/rules-of-engagement?app=Community&hide_nav=true"
          target="_blank"
        >
          Rules of Engagement
        </Link>
        <Link prefetch={false} href="https://www.gartner.com/reviews/faq?app=Community&hide_nav=true" target="_blank">
          FAQs
        </Link>
        <Link prefetch={false} href="https://www.gartner.com/en/about/policies/privacy" target="_blank">
          Privacy
        </Link>
      </p>

      <p>
        <Link prefetch={false} href="https://www.gartner.com/en/about/policies/terms-of-use" target="_blank">
          Terms of Service
        </Link>
      </p>

      <div className={styles.social}>
        <Link href="https://www.linkedin.com/showcase/gartner-peer-experiences" target="_blank" aria-label="LinkedIn">
          <LinkedInIcon />
        </Link>
        <Link href="https://twitter.com/Gartner_Peer" target="_blank" aria-label="Twitter">
          <TwitterIcon />
        </Link>
      </div>
      <Copyright />
    </Typography>
  </section>
)

export default Footer

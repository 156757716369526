import Typography from '@/components/core/Typography'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon'
import { type PropsWithChildren, useContext } from 'react'
import type { Sections } from './constants'

import { DrawerContext } from './DrawerContext'
import styles from './DrawerSubMenu.module.scss'

const DrawerSubMenu = ({ label, parent, children }: PropsWithChildren<{ label: string; parent: Sections }>) => {
  const { setLevel } = useContext(DrawerContext)
  return (
    <div className={styles.menu}>
      {parent !== undefined && (
        <button
          onClick={() => setLevel(parent)}
          className={styles.back_button}
          aria-label={`Close ${label}`}
          type="button"
        >
          <ArrowLeftIcon size={40} />
          <Typography variant="h5">{label}</Typography>
        </button>
      )}
      {children}
    </div>
  )
}

export default DrawerSubMenu

import type { DivProps, Size } from '@/types/common'
import clsx from 'clsx'
import type { ReactNode } from 'react'
import styles from './Modal.module.scss'
import ModalBody from './sub/ModalBody'
import ModalFooter from './sub/ModalFooter'
import ModalHeader from './sub/ModalHeader'

type ModalProps = {
  children: ReactNode
  className?: string
  size?: Size
  onClose?: DivProps['onClick']
} & DivProps

export const Modal = ({ children, className = '', size, onClose, ...props }: ModalProps) => {
  const classNameSize = size && styles[size]

  return (
    <div {...props} className={styles.root}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={clsx(styles.container, className)} role="dialog" aria-modal>
        <div className={clsx(styles.content, classNameSize)}>{children}</div>
      </div>
    </div>
  )
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

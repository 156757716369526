import Link from '@/components/core/Link'
import { DESTINATION_CONFERENCES_URL, EVANTA_URL, RESEARCH_BOARD_URL } from '@/components/organisms/NavHeader/constants'
import DrawerSubMenu from '../../DrawerSubMenu'
import styles from '../../DrawerSubMenu.module.scss'
import { Sections } from '../../constants'

const Events = () => (
  <DrawerSubMenu label="Events" parent={Sections.main}>
    <ul role="menu" className={styles.submenu_list}>
      <li>
        <Link href={EVANTA_URL} target="_blank" role="menuitem" tabIndex={0}>
          Evanta
        </Link>
      </li>
      <li>
        <Link href={DESTINATION_CONFERENCES_URL} target="_blank" role="menuitem" tabIndex={0}>
          Destination Conferences
        </Link>
      </li>
      <li>
        <Link href={RESEARCH_BOARD_URL} target="_blank" role="menuitem" tabIndex={0}>
          Research Board
        </Link>
      </li>
    </ul>
  </DrawerSubMenu>
)
export default Events

import Link from '@/components/core/Link'
import {
  AMBASSADORS_URL,
  BENCHMARK_SURVEYS_URL,
  ONE_MINUTE_INSIGHTS_URL,
} from '@/components/organisms/NavHeader/constants'
import DrawerSubMenu from '../../DrawerSubMenu'
import styles from '../../DrawerSubMenu.module.scss'
import { Sections } from '../../constants'

const Resources = () => (
  <DrawerSubMenu label="Resources" parent={Sections.main}>
    <ul role="menu" className={styles.submenu_list}>
      <li>
        <Link href={BENCHMARK_SURVEYS_URL} role="menuitem" tabIndex={0}>
          Benchmark Surveys
        </Link>
      </li>
      <li>
        <Link href={ONE_MINUTE_INSIGHTS_URL} role="menuitem" tabIndex={0}>
          One-Minute Insights
        </Link>
      </li>
      <li>
        <Link href={AMBASSADORS_URL} target="_blank" role="menuitem" tabIndex={0}>
          Ambassador Program
        </Link>
      </li>
    </ul>
  </DrawerSubMenu>
)
export default Resources

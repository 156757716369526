'use client'
import Button from '@/components/core/Button'
import HamburgerIcon from '@/components/icons/HamburgerIcon'
import { useState } from 'react'
import Drawer from '../NavHeader/sub/Drawer'

import clsx from 'clsx'
import styles from './DrawerMenu.module.scss'

const DrawerMenu = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const closeDrawerHandler = () => setDrawerVisible(false)
  const showDrawerHandler = () => setDrawerVisible(true)
  return (
    <>
      <Button variant="ghost" className={clsx(styles.menu, styles.button)} onClick={showDrawerHandler}>
        <HamburgerIcon />
      </Button>
      {drawerVisible && <Drawer closeHandler={closeDrawerHandler} />}
    </>
  )
}

export default DrawerMenu

'use client'

import Link from '@/components/core/Link'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon'
import OpenExternalIcon from '@/components/icons/OpenExternalIcon'
import useProfile from '@/components/providers/profile/useProfile'
import type { HTMLAttributes, MouseEventHandler } from 'react'
import { useContext } from 'react'
import { DrawerContext } from '../../DrawerContext'
import { Sections } from '../../constants'

interface ButtonItemProps extends HTMLAttributes<HTMLButtonElement> {
  label: string
  onClick: MouseEventHandler
}

const ButtonItem = ({ label, onClick, ...props }: ButtonItemProps) => (
  <button onClick={onClick} aria-label={`Open ${label}`} type="button" {...props}>
    {label}
    <ArrowRightIcon />
  </button>
)

const Main = () => {
  const {
    profile: { isAnonymous, hasPeerFinderAccess, groupsMembership },
  } = useProfile()
  const { setLevel } = useContext(DrawerContext)

  const handleClick = (section: Sections) => () => {
    setLevel(section)
  }

  const shouldRenderConnect =
    hasPeerFinderAccess ||
    (groupsMembership?.length > 0 && process.env['NEXT_PUBLIC_PRIVATE_COMMUNITIES_ENABLED'] === 'true')

  return (
    <ul role="menu">
      <li>
        <Link prefetch={false} href="/home" role="menuitem">
          Home
        </Link>
      </li>
      {!isAnonymous && (
        <>
          <li>
            <ButtonItem label="Resources" onClick={handleClick(Sections.resources)} role="menuitem" />
          </li>
          {shouldRenderConnect && (
            <li>
              <ButtonItem label="Connect" onClick={handleClick(Sections.connect)} role="menuitem" />
            </li>
          )}
          <li>
            <ButtonItem label="Events" onClick={handleClick(Sections.events)} role="menuitem" />
          </li>
        </>
      )}
      <li>
        <Link href="https://www.gartner.com/peer-insights/home" role="menuitem">
          Gartner Peer Insights™
          <OpenExternalIcon size={15} />
        </Link>
      </li>
      <li>
        <Link href="https://www.gartner.com/" role="menuitem">
          Gartner.com
          <OpenExternalIcon size={15} />
        </Link>
      </li>
    </ul>
  )
}
export default Main
